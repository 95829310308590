form.csv-form {
    width: 50%;
    border: 1px solid #003459;
    border-radius: 4px;
    padding: 10px;
    margin: 15px auto;
    text-align: left;
    background: white;
}

form.csv-form h4 {
    text-align: left;
    font-weight: lighter;
}

.csv-options {
    display: flex;
}

label.csv-label {
    display: flex;
    height: 25px;
    line-height: 25px;
    max-width: 100px;
    border-width: thin;
    border-right: 1px solid #003459;
    text-align: center;
    margin-right: 15px;
    padding-right: 15px;
}

label.csv-label:last-of-type {
    border-right: none;
}

label.csv-label span {
    font-weight: lighter;
    flex-grow: 1;
}

input.csv-checkbox {
    font-size: 20px;
    top: -2px;
    position: relative;
}
