.SignupForm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 1.4rem;
}
.SignupForm-Submit {
    display: flex;
    width: 30%;
    min-width: 192px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.75rem;
}
.signup-button {
    background-color: #003459;
}
