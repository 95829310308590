.username {
    display: inline-flex;
    font-weight: lighter;
    padding-right: 1rem;
}

.nav-link-styles {
    color: white;
    padding: 14px;
    text-decoration: none;
}

.small-nav-link {
    color: black;
    text-decoration: none;
}

.nav-link-styles:hover {
    background-color: transparent !important;
}
