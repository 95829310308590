.login-page {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: 400;
    padding-top: 1.5rem;
}
