.Signup {
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    width: 100%;
    min-height: 530px;
    background-color: inherit;
}
.Signup-Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.Signup-Panel {
    display: flex;
    padding: 1rem;
    width: 35%;
    min-width: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Signup-Login-Label {
    font-size: 1rem;
    font-weight: bold;
}

.Signup-Login-Link {
    margin-top: 0.4rem;
}
.Signup-Providers {
    margin-top: 2rem;
}
.Signup-Progress {
    margin-top: 5rem;
}
