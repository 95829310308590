.data ul {
    text-align: left;
    background-color: #eeeeee !important;
}

.data {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-top: 2rem;
}

.data-tree {
    width: 50%;
    margin: auto;
}

.loading-circle {
    margin: auto;
    width: 100%;
}

.empty {
    text-align: center;
}

.homepage-link {
    text-decoration: none;
    color: #00a8e8;
}

.rosters {
    padding: 1rem;
}

.add-username {
    text-decoration: none;
    color: white;
}
